<template>
  <f7-list
    sortable
    sortable-opposite
    @sortable:sort="sort"
  >
    <f7-list-item
      v-for="(module, moduleIndex) in edl"
      :key="'module'+moduleIndex"
      :title="$t(module.name)"
      :class="{opened: module.opened || false, selected: current.module === moduleIndex}"
      swipeout
      class="item-link"
      :animate="false"
      @click="select('module',moduleIndex)"
    >
      <f7-swipeout-actions left>
        <f7-swipeout-button
          v-if="module.opened"
          close
          class="color-grey"
          @click="unopened('module', moduleIndex)"
        >
          <i class="f7-icons">flag_fill</i>
        </f7-swipeout-button>
        <f7-swipeout-button
          close
          class="color-theme-blue"
          @click="rename(moduleIndex)"
        >
          <i class="f7-icons">pencil</i>
        </f7-swipeout-button>
      </f7-swipeout-actions>
      <f7-swipeout-actions right>
        <f7-swipeout-button
          close
          class="color-red"
          @click="deleteModule(moduleIndex)"
        >
          <i class="f7-icons">trash</i>
        </f7-swipeout-button>
      </f7-swipeout-actions>
    </f7-list-item>
  </f7-list>
</template>

<script>
export default {
  name: "EdlEditListModule",
  props: {
    edl: {
      type: Object,
      default: null
    },
    current: {
      type: Object,
      required: true
    }
  },
  emits: ['sort', 'select', 'rename', 'unopened', 'delete'],
  methods: {
    sort: function (event) {
      this.$emit('sort', event);
    },
    select: function (type, index) {
      this.$emit('select', type, index);
    },
    unopened: function (type, index) {
      this.$emit('unopened', type, index);
    },
    rename: function (moduleIndex) {
      this.$emit('rename', moduleIndex);
    },
    deleteModule: function (index) {
      this.$emit('delete', index);
    }
  }
}
</script>

<style lang="scss" scoped>
li.selected {
  background-color: var(--f7-theme-color);
  color: var(--f7-theme-color-light-grey);
  --f7-list-chevron-icon-color: var(--f7-theme-color-light-grey);
  :deep(.item-inner):after {
    background-color: var(--f7-theme-color);
  }
}
li:not(.selected).opened {
  background-color: var(--f7-theme-color-grey);
}
</style>
