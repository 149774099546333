import _ from "lodash";

const structure = {
  'bien.genre' : [
    "Appartement",
    "Maison",
    "Studio",
    "Immeuble",
    "Local",
    "Local commercial",
    "Local industriel",
    "Garage",
    "Autre"
  ],
  'bien.typeLogement' : [
    "Studio",
    "T1",
    "T2",
    "T3",
    "T4",
    "T5",
    "T6",
    "T7",
    "Local",
    "Garage",
    "Autre"
  ],
  'bien.etat' : [
    "N.C",
    "Neuf",
    "Très bon état",
    "Bon état",
    "Etat usage",
    "Etat moyen",
    "Mauvais état",
    "Hors service",
  ]
};

export default (path) => {
  let data = structure[path];
  if(data === undefined) {
    return null;
  }

  if(!_.includes(data, "")) {
    data.unshift("");
  }

  return data;
}
