<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top
      v-if="user"
      :title="user.login"
      :back="back"
    />
    <navigation-bottom current="account" />

    <f7-page-content v-if="user">
      <f7-block class="text-align-center">
        <img
          v-if="user.logo"
          :src="user.logo"
          class="logo"
        >
        <f7-icon
          v-else
          f7="person_crop_circle_badge_plus"
          size="80"
        />
        <input
          ref="logoInput"
          class="file-input"
          type="file"
          accept="image/*"
          @change="uploadLogo"
        >
      </f7-block>

      <f7-list inset>
        <f7-list-item
          link="#"
          header="Groupe"
          after="Sélectionner"
          :class="{disabled: !isOnline}"
          smart-select
          :smart-select-params="{
            openIn: 'popup',
            searchbar: true,
            searchbarPlaceholder: 'Recherchez un groupe',
            closeOnSelect: true,
            scrollToSelectedItem: true}"
        >
          <select
            v-model="groupId"
            name="groups"
          >
            <option
              v-if="isSuperAdmin"
              value=""
              :selected="groupId === null"
            >
              ----- Aucun groupe -----
            </option>
            <option
              v-for="group in groups"
              :key="group.id"
              :value="group.id"
              :selected="groupId === group.id"
            >
              {{ group.name }}
            </option>
          </select>
          <template #media>
            <f7-icon f7="person_3" />
          </template>
        </f7-list-item>
        <f7-list-item
          :header="groupId ? 'Administrateur groupe' : 'Super admin'"
          :class="{disabled: !isOnline}"
        >
          <template #media>
            <f7-icon f7="lock_shield" />
          </template>
          <f7-toggle
            :checked="admin"
            @change="admin = $event.target.checked"
          />
        </f7-list-item>
        <f7-list-item
          header="Adresse e-mail"
          :title="user.email"
          after="Modifier"
          :class="{disabled: !isOnline}"
          @click="updateEmail"
        >
          <template #media>
            <f7-icon f7="envelope" />
          </template>
        </f7-list-item>
        <f7-list-item
          header="Mot de passe"
          after="Définir un nouveau"
          :class="{disabled: !isOnline}"
          @click="newPassword"
        >
          <template #media>
            <f7-icon f7="lock_fill" />
          </template>
        </f7-list-item>

      </f7-list>
      <f7-block-title>Paramétrage de l'utilisateur</f7-block-title>
      <f7-list inset>
        <f7-list-item
          header="Modifier le logo"
          after="Format attendu : 150x150"
          class="list-action"
          :class="{disabled: !isOnline}"
          @click="updateLogo"
        >
          <template #media>
            <f7-icon f7="camera_on_rectangle_fill" />
          </template>
        </f7-list-item>
        <f7-list-item
          :link="f7router.generateUrl({name: 'userCostingCalibration', params: {id: user.id}})"
          header="Calibrage de l'auto-chiffrage"
          class="list-action"
          :class="{disabled: !isOnline}"
        >
          <template #media>
            <f7-icon f7="money_euro_circle" />
          </template>
        </f7-list-item>
      </f7-list>
      <f7-block-title>Options de l'utilisateur</f7-block-title>
      <f7-list inset>
        <f7-list-item
          v-if="isSuperAdmin"
          header="Activer les Auto-EDLs"
          :class="{disabled: !isOnline}"
        >
          <template #media>
            <f7-icon f7="wand_stars" />
          </template>
          <f7-toggle
            :checked="canAutoEdl"
            @change="canAutoEdl = $event.target.checked"
          />
        </f7-list-item>
        <f7-list-item
          header="Limiter l'utilisateur à un seul profil"
          :footer="user.group?.uniqueProfile ? 'Activé niveau groupe' : ''"
          :class="{disabled: !isOnline || user.group?.uniqueProfile}"
        >
          <template #media>
            <f7-icon f7="person_alt_circle" />
          </template>
          <f7-toggle
            :checked="uniqueProfile"
            @change="uniqueProfile = $event.target.checked"
          />
        </f7-list-item>
        <f7-list-item
          header="Afficher les EDLs de tous les profils par défaut dans la liste des EDLs sauvegardés"
          :footer="user.group?.defaultListAllProfiles ? 'Activé niveau groupe' : ''"
          :class="{disabled: !isOnline || user.group?.defaultListAllProfiles}"
        >
          <template #media>
            <f7-icon f7="person_2_alt" />
          </template>
          <f7-toggle
            :checked="defaultListAllProfiles"
            @change="defaultListAllProfiles = $event.target.checked"
          />
        </f7-list-item>
      </f7-list>

      <f7-block-title>Profils</f7-block-title>
      <f7-list
        v-if="user.profiles.length > 0"
        media-list
        inset
      >
        <f7-list-item
          v-for="profile in user.profiles"
          :key="profile.id"
          :title="profile.name"
        >
          <template #text>
            Ajouté le {{ formatDate(profile.date, 'PPP p') }}
          </template>
          <template
            v-if="profile.default"
            #after-title
          >
            <div>
              <f7-icon f7="heart_fill" />
              Principal
            </div>
          </template>
        </f7-list-item>
      </f7-list>
      <f7-block-footer v-else>
        Aucun profil ajouté
      </f7-block-footer>
      <f7-list class="delete-section" inset>
        <f7-list-button
          title="Supprimer l'utilisateur"
          color="red"
          :class="{disabled: !isOnline}"
          @click="this.delete"
        />
      </f7-list>
      <p class="delete-info" v-if="user.groupId">⚠︎ La suppression de l'utilisateur entrainera un transfert de ses EDLs à l'administrateur du groupe {{ user.group.name }} (sur son profil principal).</p>
      <p class="delete-info" v-else>⚠︎ La suppression de l'utilisateur entrainera une anonymisation de ses EDLs.</p>
    </f7-page-content>
  </f7-page>
</template>

<script>
  import firewall from "../../../mixins/firewall";
  import {mapActions, mapGetters} from "vuex";
  import NavigationTop from "../../../layout/NavigationTop.vue";
  import NavigationBottom from "../../../layout/NavigationBottom.vue";
  import formatDate from "@/components/filters/format_date";
  import api from "@/api";
  import { f7 } from "framework7-vue";
  import logo from "@/components/mixins/logo";
  import {nextTick} from "vue";
  import offline from "@/components/mixins/offline";

  export default {
    name: "UserShow",
    components: {NavigationBottom, NavigationTop},
    mixins: [firewall, logo, offline],
    props: {
      id: {
        type: String,
        default: null
      },
      f7router: {
        type: Object,
        default: null
      }
    },
    computed: {
      ...mapGetters('user', [
        'getUserById',
      ]),
      ...mapGetters('userGroup', [
        'getAllUserGroups',
      ]),
      currentUserId() {
        return parseInt(this.id)
      },
      user() {
        return this.getUserById(this.currentUserId)
      },
      groups() {
        return this.me?.groupId ? this.getAllChildrenOfGroupId()(this.me.groupId, this.me.id === this.user.id) : this.getAllUserGroups;
      },
      back: function () {
        if(this.user.group !== undefined && this.user.group !== null) {
          return {name: 'userListGroup', params: {id: this.user.group.id}};
        } else if(this.isSuperAdmin) {
          return {name: 'users'};
        } else {
          return {name: 'account'};
        }
      },
      groupId: {
        // getter
        get: function () {
          return this.user?.groupId;
        },
        // setter
        set: function (newValue) {
          this.updateUser({ id: this.currentUserId, changes: { 'groupId': newValue }, groupId: this.groupId })
        }
      },
      admin: {
        // getter
        get: function () {
          return this.user.admin
        },
        // setter
        set: function (newValue) {
          this.updateUser({ id: this.currentUserId, changes: { 'admin': newValue }, groupId: this.groupId })
        }
      },
      canAutoEdl: {
        // getter
        get: function () {
          return this.user.canAutoEdl
        },
        // setter
        set: function (newValue) {
          this.updateUser({ id: this.currentUserId, changes: { 'canAutoEdl': newValue }, groupId: this.groupId })
        }
      },
      uniqueProfile: {
        // getter
        get: function () {
          return this.user.uniqueProfile
        },
        // setter
        set: function (newValue) {
          this.updateUser({ id: this.currentUserId, changes: { 'uniqueProfile': newValue }, groupId: this.groupId })
        }
      },
      defaultListAllProfiles: {
        // getter
        get: function () {
          return this.user.defaultListAllProfiles
        },
        // setter
        set: function (newValue) {
          this.updateUser({ id: this.currentUserId, changes: { 'defaultListAllProfiles': newValue }, groupId: this.groupId })
        }
      }
    },
    mounted() {
      nextTick(() => {
        this.limitToAdmins();

        f7.preloader.show();
        this.pagePreloaderRunning = true;
        this.fetchUser(this.currentUserId).finally(() => f7.preloader.hide());
        this.fetchUserGroups();
      })
    },
    methods: {
      ...mapActions('user', [
        'fetchUser',
        'updateUser',
        'deleteUser'
      ]),
      ...mapActions('userGroup', [
        'fetchUserGroups',
      ]),
      ...mapGetters('userGroup', [
        'getAllChildrenOfGroupId'
      ]),
      delete() {
        f7.dialog.confirm('Êtes-vous certains de vouloir supprimer cet utilisateur&nbsp;? Cette action est définitive.',
          () => {
            f7.dialog.preloader('Suppression...');
            const userGroupId = this.user.groupId;
            this.deleteUser(this.user)
              .then(() => {
                f7.dialog.close()
                f7.views.main.router.navigate(this.backAfterDelete(userGroupId));
              })
              .catch((error) => {
                f7.dialog.alert('Impossible de supprimer l\'utilisateur');
                console.error(error);
              })
          },
        )

      },
      updateEmail() {
        f7.dialog.prompt('Nouvelle adresse e-mail',
          (value) => {
            f7.dialog.preloader('Enregistrement...');
            this.updateUser({ id: this.user.id, changes: {email: value}, groupId: this.groupId })
              .then(() => f7.dialog.close())
              .catch(() => f7.dialog.alert('L\'adresse e-mail n\'a pas pu être mise à jour'))
          },
          null,
          this.user.email
        )
      },
      doUploadLogo(payload) { //See logo mixin
        return this.updateUser({id: this.id, changes: payload, groupId: this.groupId});
      },
      newPassword() {
        f7.dialog.password('Je définis son nouveau mot de passe', 'Mot de passe de "'+this.user.login+'"',
          (password) => {
            f7.dialog.password('Je confirme son nouveau mot de passe','Mot de passe de "'+this.user.login+'"',
              (confirmPassword) => {
                if(password !== confirmPassword) {
                  f7.dialog.create({
                    title: "Attention",
                    text: "Les deux mots de passe ne correspondent pas, veuillez essayer à nouveau",
                    buttons: [
                      {
                        text: 'OK',
                        bold: true,
                        close: true
                      }
                    ]
                  }).open();
                } else {
                  f7.dialog.preloader('Enregistrement...');
                  api.updatePassword(this.user.id, password)
                    .then(() => {
                      f7.dialog.close();
                      f7.dialog.create({
                        title: "Le mot de passe de l'utilisateur a bien été mis à jour",
                        text: "N'oubliez pas de le lui communiquer (de manière sécurisée, de préférence à l'oral).",
                        buttons: [
                          {
                            text: 'OK',
                            bold: true,
                            close: true
                          }
                        ]
                      }).open();
                    })
                    .catch(() => {
                      f7.dialog.create({
                        title: "Erreur",
                        text: "Le mot de passe de l'utilisateur n'a pas pu être mis à jour...veuillez nous contacter",
                        buttons: [
                          {
                            text: 'OK',
                            bold: true,
                            close: true
                          }
                        ]
                      }).open();
                    });
                }
              }
            )
          }
        )
      },
      formatDate: function (value, pattern) {
        return formatDate(value, pattern);
      },
      backAfterDelete (groupId) {
        if(groupId !== undefined) {
          return {name: 'userListGroup', params: {id: groupId}};
        } else if(this.isSuperAdmin) {
          return {name: 'users'};
        } else {
          return {name: 'account'};
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .logo {
    height: 150px;
    width: 150px;
  }
  .file-input {
    display: none;
  }
  .delete-section {
    margin-top: 100px;
  }
  .delete-info {
    position: relative;
    font-size: 10px;
    color: grey;
    text-align: center;
    margin-top: -10px;
  }
</style>
