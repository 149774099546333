<template>
  <f7-list
    sortable
    sortable-opposite
    inset
    media-list
    @sortable:sort="sort"
  >
    <f7-list-button
      :class="{disabled: currentEntry.content.length === 0}"
      @click="$refs.galleryPopup.open()"
    >
      <i class="f7-icons">photo_on_rectangle</i> ouvrir galerie
    </f7-list-button>
    <f7-list-item
      v-for="(subEntry, subEntryIndex) in currentEntry.content"
      :key="'photo'+subEntryIndex"
      :title="getPhotoTitle(subEntry.rate.photo)"
      :subtitle="getPhotoContext(subEntry.rate.photo)"
      :text="getPhotoSubTitle(subEntry.rate)"
      :class="{selected: current.subEntry === subEntryIndex}"
      media-item
      class="item-link"
      swipeout
      @click="select('subEntry', subEntryIndex)"
    >
      <f7-swipeout-actions left>
        <f7-swipeout-button
          close
          class="color-grey"
          @click="unopened('subEntry', subEntryIndex)"
        >
          <i class="f7-icons">flag_fill</i>
        </f7-swipeout-button>
      </f7-swipeout-actions>
      <f7-swipeout-actions right>
        <f7-swipeout-button
          close
          class="color-red"
          @click="deletePhoto(subEntryIndex)"
        >
          <i class="f7-icons">trash</i>
        </f7-swipeout-button>
      </f7-swipeout-actions>
      <template #media>
        <img
          :src="getMedia(subEntryIndex)"
          width="44"
        >
      </template>
    </f7-list-item>

    <f7-list-item
      v-for="i in addingPhotos"
      :key="'addingPhoto'+i"
      title="Nouvelle photo"
      text="Chargement..."
      media-item
    >
      <template #media>
        <f7-skeleton-block class="photo-skeleton skeleton-effect-fade" />
      </template>
    </f7-list-item>

    <f7-list-button @click="openPhotoInput(false)">
      <f7-preloader v-if="openingFileSelector" />
      <i
        v-else
        class="f7-icons"
      >camera</i>
    </f7-list-button>

    <f7-button
      v-if="currentEntry.content.length > 0"
      class="empty-photos"
      @click="deleteAllFromPhotosEntry"
    >
      <i class="f7-icons">trash</i> Vider les photos
    </f7-button>

    <f7-photo-browser
      ref="galleryPopup"
      :photos="gallery"
      type="popup"
      :routable-modals="false"
      navbar-of-text="sur"
      popup-close-link-text="Fermer"
    />
  </f7-list>
</template>

<script>
import edlEditList from "@/components/mixins/edlEditList";
import hasMedia from "@/components/mixins/hasMedia";

export default {
  name: "EdlEditListSubEntryPhotos",
  mixins: [edlEditList, hasMedia],
  props: {
    current: {
      type: Object,
      required: true
    },
    currentEntry: {
      type: Object,
      required: true
    },
    gallery: {
      type: Array,
      default: () => []
    },
    addingPhotos: {
      type: Number,
      default: 0
    },
    openingFileSelector: {
      type: Boolean,
      default: false
    },
    immersive: {
      type: Boolean,
      default: false
    }
  },
  emits: ['select', 'unopened', 'delete', 'delete-all', 'sort', 'open-photo-input'],
  methods: {
    select: function (type, index) {
      this.$emit('select', type, index);
    },
    sort: function (event) {
      this.$emit('sort', event);
    },
    unopened: function (type, index) {
      this.$emit('unopened', type, index);
    },
    deletePhoto: function (index) {
      this.$emit('delete', index);
    },
    deleteAllFromPhotosEntry: function () {
      this.$emit('delete-all');
    },
    getMedia: function (index) {
      return this.gallery[index];
    },
    openPhotoInput: function (multiple) {
      this.$emit('open-photo-input', multiple);
    }
  },
}
</script>

<style lang="scss" scoped>
li.selected {
  background-color: var(--f7-theme-color);
  color: var(--f7-theme-color-light-grey);
  --f7-list-chevron-icon-color: var(--f7-theme-color-light-grey);
  :deep(.item-inner):after {
    background-color: var(--f7-theme-color);
  }
}
li:not(.selected).opened {
  background-color: var(--f7-theme-color-grey);
}
.media-item {
  --f7-list-media-item-title-font-weight: 400;
  :deep(.item-title) {
    font-size: 12px;
  }
  :deep(.item-subtitle) {
    font-style: italic;
    font-size: 14px;
  }
}
.empty-photos {
  margin-top: 15px;
  font-size: 12px;
  i {
    font-size: 12px;
  }
}
.photo-skeleton {
  width: 44px;
  height: 44px;
}
</style>
