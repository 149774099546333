import _ from "lodash";

export default {
  data: function () {
    return {
      //Special entries:
      showCountLabelTotalOnEntry: ['Remise des clés'],
      isCountTotalSubEntry: ['Total des clés'],
    }
  },
  methods: {
    entryHasNumberCount: function (entry) {
      return _.reduce(entry.content, (count, subEntry) => {
        let subEntryCount = this.subEntryHasNumberCount(subEntry);
        if(!this.isCountTotalSubEntry.includes(subEntry.name) && subEntryCount !== null) {
          if(count === null) {
            count = parseInt(subEntryCount);
          } else {
            count += parseInt(subEntryCount);
          }
        }
        return count;
      }, null);
    },
    subEntryHasNumberCount: function (subEntry) {
      let count = null;
      if(_.has(subEntry, 'rate') && _.has(subEntry.rate, 'number') && subEntry.rate.number !== null && subEntry.rate.number !== '') {
        count = parseInt(subEntry.rate.number);
      }
      return count;
    },
    getConditionColorClasses: function (entry, asBackground = false) {
      //Warning: counter-intuitive result values:
      //asBackground -> null = grey circle
      //asBackground -> 'bg-color-transparent border-black' = black line circle with no background
      //!asBackground -> null = black line circle with no background
      //!asBackground -> "color-transparent" = no circle at all

      if(this.showCountLabelTotalOnEntry.includes(entry.name) && asBackground) {
        return null;
      } else if((entry.rate === undefined
        || entry.content !== undefined)) {
        return asBackground ? null : 'color-transparent';
      } else if (entry.rate.condition === 'Neuf' || entry.rate.condition === 'Très bon état') {
        return 'color-theme ' + (asBackground ? 'bg-color-white border-shadow text-black' : 'color-white-with-border');
      } else if (entry.rate.condition === 'Bon état') {
        return 'color-theme ' + (asBackground ? 'bg-color-green' : 'color-green');
      } else if (entry.rate.condition === 'Etat usage') {
        return 'color-theme ' + (asBackground ? 'bg-color-yellow text-black' : 'color-yellow');
      } else if (entry.rate.condition === 'Etat moyen') {
        return 'color-theme ' + (asBackground ? 'bg-color-orange' : 'color-orange');
      } else if (entry.rate.condition === 'Mauvais état' || entry.rate.condition === 'Dégradé' || entry.rate.condition === 'Hors service') {
        return 'color-theme ' + (asBackground ? 'bg-color-red' : 'color-red');
      } else if (entry.rate.condition !== undefined && entry.rate.condition !== null && entry.rate.condition !== '') {
        return asBackground ? 'bg-color-transparent border-black text-black' : null;
      }

      return asBackground ? null : 'color-transparent';
    },
    subEntriesHaveAtLeastOneIndicator: function (entry) {
      let atLeastOne = false;
      let subEntries = entry.content;
      _.each(subEntries, (subEntry) => {
        if(this.hasContextGallery(subEntry, 'subEntry', entry)
          || (subEntry.rate.other !== undefined && subEntry.rate.other !== "")
          || (subEntry.rate.cleaning !== undefined && subEntry.rate.cleaning !== null && subEntry.rate.cleaning !== '' && subEntry.rate.cleaning !== 'Propre')
          || (subEntry.rate.state !== undefined && (subEntry.rate.state === 'Ne fonctionne pas' || subEntry.rate.state === 'Dysfonctionnement'))
        ) {
          atLeastOne = true;
          return false;//exits loop
        }
      });
      return atLeastOne;
    },
  }
}
