<template>
  <f7-list
    :inset="immersive"
  >
    <f7-list-button
      v-if="currentEntry.content.length === 0"
      @click="addCustomSubEntry"
    >
      <i class="f7-icons">plus_circle</i> Ajoutez un équipement
    </f7-list-button>
    <f7-list-item
      v-for="(subEntry, subEntryIndex) in currentEntry.content"
      v-else
      :key="'subEntry'+subEntryIndex"
      class="item-link"
      :title="$t(subEntry.name)"
      :class="{opened: subEntry.opened || false, selected: current.subEntry === subEntryIndex}"
      swipeout
      @click="select('subEntry', subEntryIndex)"
    >
      <template #media>
        <edl-edit-list-rate-indicators-before
          type="subEntry"
          :entry="subEntry"
          :has-context-gallery="hasContextGallery(subEntry, 'subEntry')"
        />
      </template>
      <template #after>
        <edl-edit-list-rate-indicators-after
          :entry="subEntry"
          :has-context-gallery="hasContextGallery(subEntry, 'subEntry')"
        />
      </template>
      <f7-swipeout-actions left>
        <f7-swipeout-button
          close
          class="color-grey"
          @click="unopened('subEntry', subEntryIndex)"
        >
          <i class="f7-icons">flag_fill</i>
        </f7-swipeout-button>
        <f7-swipeout-button
          v-if="!cantRename.includes(subEntry.name)"
          close
          class="color-theme-blue"
          @click="rename(subEntryIndex)"
        >
          <i class="f7-icons">pencil</i>
        </f7-swipeout-button>
      </f7-swipeout-actions>
      <f7-swipeout-actions right>
        <f7-swipeout-button
          v-if="!cantDelete.includes(subEntry.name)"
          close
          class="color-red"
          @click="deleteSubEntry(subEntryIndex)"
        >
          <i class="f7-icons">trash</i>
        </f7-swipeout-button>
      </f7-swipeout-actions>
    </f7-list-item>
    <f7-list-button
      v-if="currentEntry.content.length > 0"
      @click="addCustomSubEntry"
    >
      <i class="f7-icons">plus_circle</i>
    </f7-list-button>
  </f7-list>
</template>

<script>
import EdlEditListRateIndicatorsAfter from "@/components/pages/Edl/EdlEdit/EdlEditList/EdlEditListRateIndicatorsAfter.vue";
import edlEditList from "@/components/mixins/edlEditList";
import EdlEditListRateIndicatorsBefore
  from "@/components/pages/Edl/EdlEdit/EdlEditList/EdlEditListRateIndicatorsBefore.vue";
import hasGallery from "@/components/mixins/hasGallery";

export default {
  name: "EdlEditListSubEntryCustom",
  components: {EdlEditListRateIndicatorsBefore, EdlEditListRateIndicatorsAfter},
  mixins: [edlEditList, hasGallery],
  props: {
    current: {
      type: Object,
      required: true
    },
    currentEntry: {
      type: Object,
      required: true
    },
    currentModule: {
      type: Object,
      required: true
    },
    contextGallery: {
      type: Array,
      default: () => []
    },
    cantRename: {
      type: Array,
      default: () => []
    },
    cantDelete: {
      type: Array,
      default: () => []
    },
    immersive: {
      type: Boolean,
      default: false
    }
  },
  emits: ['select', 'unopened', 'delete', 'sort', 'rename', 'addCustomSubEntry'],
  methods: {
    select: function (type, index) {
      this.$emit('select', type, index);
    },
    sort: function (event) {
      this.$emit('sort', event);
    },
    unopened: function (type, index) {
      this.$emit('unopened', type, index);
    },
    rename: function (subEntryIndex) {
      this.$emit('rename', subEntryIndex);
    },
    deleteSubEntry: function (subEntryIndex) {
      this.$emit('delete', subEntryIndex);
    },
    addCustomSubEntry: function (event) {
      this.$emit('addCustomSubEntry', event);
    }
  }
}
</script>

<style lang="scss" scoped>
li.selected {
  background-color: var(--f7-theme-color);
  color: var(--f7-theme-color-light-grey);
  --f7-list-chevron-icon-color: var(--f7-theme-color-light-grey);
  :deep(.item-inner):after {
    background-color: var(--f7-theme-color);
  }
}
li:not(.selected).opened {
  background-color: var(--f7-theme-color-grey);
}
.rate-indicator {
  display: block;
  font-size: 12px;
}
.number-count {
  width: 13px;
  height: 13px;
  background-color: var(--f7-theme-color-dark-grey);
  color: white;
  padding: 4px;
  border-radius: 50%;
  text-align: center;
  line-height: 13px;
  margin-left: -5px;
  margin-right: -4px;
}
</style>
