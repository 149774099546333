<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top
      title="Fiche groupe"
      back="userGroups"
    />

    <navigation-bottom current="account" />

    <f7-page-content v-if="userGroup">
      <f7-block class="text-align-center">
        <img
          v-if="userGroup.logo"
          :src="userGroup.logo"
          class="logo"
        >
        <f7-icon
          v-else
          f7="person_crop_circle_badge_plus"
          size="80"
        />
        <input
          ref="logoInput"
          class="file-input"
          type="file"
          accept="image/*"
          @change="uploadLogo"
        >
      </f7-block>

      <f7-list inset>
        <f7-list-item
          header="Nom du groupe"
          :title="userGroup.name"
          after="Modifier"
          :class="{disabled: !isOnline}"
          @click="updateName"
        >
          <template #media>
            <f7-icon f7="envelope" />
          </template>
        </f7-list-item>
        <f7-list-item
          v-if="isSuperAdmin"
          link="#"
          header="Groupe parent"
          after="Sélectionner"
          smart-select
          :smart-select-params="{
            openIn: 'popup',
            searchbar: true,
            searchbarPlaceholder: 'Recherchez un groupe',
            closeOnSelect: true,
            scrollToSelectedItem: true}"
        >
          <select
            v-if="groups"
            name="groups"
            :value="userGroup.parentId"
            @change="updateParentId"
          >
            <option value="">
              ----- Aucun groupe -----
            </option>
            <option
              v-for="group in groups"
              :key="group.id"
              :value="group.id"
              :disabled="group.id === userGroup.id"
              :selected="group.id === userGroup.parentId"
            >
              {{ group.name }}
            </option>
          </select>
          <template #media>
            <f7-icon f7="person_3" />
          </template>
        </f7-list-item>
        <f7-list-button @click="goToUsers">
          Voir les utilisateurs du groupe
          <f7-icon f7="arrow_up_right_square" />
        </f7-list-button>
      </f7-list>
      <f7-block-title>Paramétrage du groupe</f7-block-title>
      <f7-list inset>
        <f7-list-item
          header="Modifier le logo"
          after="Format attendu : 150x150"
          class="list-action"
          :class="{disabled: !isOnline}"
          @click="updateLogo"
        >
          <template #media>
            <f7-icon f7="camera_on_rectangle_fill" />
          </template>
        </f7-list-item>

        <f7-list-item
          class="list-action"
          :class="{disabled: !isOnline}"
          :link="f7router.generateUrl({name: 'userGroupCostingCalibration', params: {id}})"
          header="Calibrage de l'auto-chiffrage"
        >
          <template #media>
            <f7-icon f7="money_euro_circle" />
          </template>
        </f7-list-item>
      </f7-list>
      <f7-block-title>Options du groupe</f7-block-title>
      <f7-list inset>
        <f7-list-item
          header="Désactiver la modification des mots de passe par l’utilisateur"
          :footer="userGroup.parent?.lockPasswords ? 'Activé groupe parent' : ''"
          :class="{disabled: !isOnline || userGroup.parent?.lockPasswords}"
        >
          <template #media>
            <f7-icon f7="lock_slash_fill" />
          </template>
          <f7-toggle
            :checked="userGroup.lockPasswords"
            @toggle:change="updateLockPasswords"
          />
        </f7-list-item>
        <f7-list-item
          header="Partager les EDLs entre chaque utilisateur du groupe"
          :footer="userGroup.parent?.shareEdls ? 'Activé groupe parent' : ''"
          :class="{disabled: !isOnline || userGroup.parent?.shareEdls}"
        >
          <template #media>
            <f7-icon f7="person_2_square_stack" />
          </template>
          <f7-toggle
            :checked="userGroup.shareEdls"
            @toggle:change="updateShareEdls"
          />
        </f7-list-item>
        <f7-list-item
          header="Limiter les utilisateurs du groupe à un seul profil"
          :footer="userGroup.parent?.uniqueProfile ? 'Activé groupe parent' : ''"
          :class="{disabled: !isOnline || userGroup.parent?.uniqueProfile}"
        >
          <template #media>
            <f7-icon f7="person_alt_circle" />
          </template>
          <f7-toggle
            :checked="userGroup.uniqueProfile"
            @toggle:change="updateUniqueProfile"
          />
        </f7-list-item>
        <f7-list-item
          header="Afficher les EDLs de tous les profils par défaut dans la liste des EDLs sauvegardés"
          :footer="userGroup.parent?.defaultListAllProfiles ? 'Activé groupe parent' : ''"
          :class="{disabled: !isOnline || userGroup.parent?.defaultListAllProfiles}"
        >
          <template #media>
            <f7-icon f7="person_2_alt" />
          </template>
          <f7-toggle
            :checked="userGroup.defaultListAllProfiles"
            @toggle:change="updateDefaultListAllProfiles"
          />
        </f7-list-item>
      </f7-list>

      <f7-list class="delete-section" inset>
        <f7-list-button
          title="Supprimer le groupe"
          color="red"
          :class="{disabled: !isOnline}"
          @click="this.delete"
        />
      </f7-list>
      <p class="delete-info" v-if="userGroup.parent === undefined || userGroup.parent === null">⚠︎ La suppression de ce groupe parent entrainera une suppression de tous ses utilisateurs, de ses sous-groupes et de leurs utilisateurs, suivi d'une anonymisation de tous leurs EDLs.</p>
      <p class="delete-info" v-else>⚠︎ En supprimant ce sous-groupe, tous ses utilisateurs et leurs EDLs seront transférés vers le groupe parent.</p>
    </f7-page-content>
  </f7-page>
</template>

<script>
  import firewall from "../../../mixins/firewall";
  import {mapActions, mapGetters} from "vuex";
  import NavigationTop from "../../../layout/NavigationTop.vue";
  import NavigationBottom from "../../../layout/NavigationBottom.vue";
  import {clone} from "lodash";
  import { f7 } from "framework7-vue";
  import {nextTick} from "vue";
  import logo from "@/components/mixins/logo";
  import offline from "@/components/mixins/offline";

  export default {
    name: "UserGroupShow",
    components: {NavigationBottom, NavigationTop},
    mixins: [firewall, logo, offline],
    props: {
      id: {
        type: Number,
        default: null
      },
      f7router: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        userGroup: null
      }
    },
    computed: {
      ...mapGetters('userGroup', {
        'groups': 'getRootUserGroups',
      })
    },
    mounted() {
      nextTick(() => {
        this.limitToAdmins();

        f7.preloader.show();

        Promise.all([
          this.fetchUserGroup(parseInt(this.id)).then((data) => this.userGroup = data),
          this.fetchUserGroups()
        ]).finally(() => f7.preloader.hide());
      });
    },
    methods: {
      ...mapActions('userGroup', [
        'fetchUserGroup',
        'updateUserGroup',
        'deleteUserGroup',
        'fetchUserGroups',
      ]),
      delete() {
        f7.dialog.confirm('Êtes-vous certains de vouloir supprimer ce groupe utilisateur&nbsp;? Cette action est définitive.',
          () => {
            f7.dialog.preloader('Suppression...');
            this.deleteUserGroup(this.userGroup)
              .then(() => {
                f7.dialog.close()
                this.f7router.navigate({name: 'userGroups'})
              })
              .catch(() => f7.dialog.alert('Impossible de supprimer le groupe'))
          },
        )
      },
      updateName() {
        f7.dialog.prompt('Nouveau nom',
          (value) => {
            f7.dialog.preloader('Enregistrement...');
            let newUserGroup = clone(this.userGroup);
            newUserGroup.name = value;
            this.updateUserGroup({ id: this.userGroup.id, payload: newUserGroup })
              .then(() => {
                f7.dialog.close();
                this.userGroup.name = value;
              })
              .catch(() => f7.dialog.alert('Le nom n\'a pas pu être mise à jour'))
          },
          null,
          this.userGroup.name
        )
      },
      updateLockPasswords(value) {
        let newUserGroup = clone(this.userGroup);
        newUserGroup.lockPasswords = value;
        this.updateUserGroup({ id: this.userGroup.id, payload: newUserGroup })
          .then(() => {
            this.userGroup.lockPasswords = value;
          })
          .catch(() => f7.dialog.alert('Il y a eu une erreur pour activer cette option'))
      },
      updateShareEdls(value) {
        let newUserGroup = clone(this.userGroup);
        newUserGroup.shareEdls = value;
        this.updateUserGroup({ id: this.userGroup.id, payload: newUserGroup })
          .then(() => {
            this.userGroup.shareEdls = value;
          })
          .catch(() => f7.dialog.alert('Il y a eu une erreur pour activer cette option'))
      },
      updateUniqueProfile(value) {
        let newUserGroup = clone(this.userGroup);
        newUserGroup.uniqueProfile = value;
        this.updateUserGroup({ id: this.userGroup.id, payload: newUserGroup })
          .then(() => {
            this.userGroup.uniqueProfile = value;
          })
          .catch(() => f7.dialog.alert('Il y a eu une erreur pour activer cette option'))
      },
      updateDefaultListAllProfiles(value) {
        let newUserGroup = clone(this.userGroup);
        newUserGroup.defaultListAllProfiles = value;
        this.updateUserGroup({ id: this.userGroup.id, payload: newUserGroup })
          .then(() => {
            this.userGroup.defaultListAllProfiles = value;
          })
          .catch(() => f7.dialog.alert('Il y a eu une erreur pour activer cette option'))
      },
      updateParentId(event) {
        let value = parseInt(event.target.value);
        let newUserGroup = clone(this.userGroup);
        newUserGroup.parentId = value;
        this.updateUserGroup({ id: this.userGroup.id, payload: newUserGroup })
          .then(() => {
            this.userGroup.parentId = value;
          })
          .catch(() => f7.dialog.alert('Il y a eu une erreur pour modifier le groupe parent'))
      },
      doUploadLogo(payload) { //See logo mixin
        let newUserGroup = clone(this.userGroup);
        newUserGroup.logo = payload.logo;
        return this.updateUserGroup({id: this.userGroup.id, payload: newUserGroup}).then(() => {
          this.fetchUserGroup(parseInt(this.id)).then((data) => this.userGroup = data);
        });
      },
      goToUsers() {
        let url = this.f7router.generateUrl({name: 'userListGroup', params: {id: this.userGroup.id}});
        window.open('#'+url, '_blank');
      }
    }
  }
</script>

<style lang="scss" scoped>
  .logo {
    height: 150px;
    width: 150px;
  }
  .file-input {
    display: none;
  }
  .delete-section {
    margin-top: 100px;
  }
  .delete-info {
    position: relative;
    font-size: 10px;
    color: grey;
    text-align: center;
    margin-top: -10px;
    width: 80%;
    margin-left: 10%;
  }
</style>
